.App {
  /* text-align: center; */
}

.App-logo {
  margin-top: 5px;
  height: 5vmin;
}

.App-header {
  width: 100vw;
  background-color: #009900;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-menu-container {
  align-self: baseline;
  display: inline;
}

.pick-button {
  background-color: #3f51b5 !important;
  color: white !important;
}

.pick-button:hover {
  background-color: blue !important;
  color: white !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
